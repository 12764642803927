<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            v-if="langInfo"
            :src="$i18n.locale == 'fa' ? fa_auth_banner : en_auth_banner"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            {{ $t("Pages.Auth.Title.change_pass") }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t("Pages.Auth.Title.enter_info") }}
          </b-card-text>

          <!-- form -->
          <b-form class="auth-register-form mt-2">
            <!-- forgot password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">{{
                  $t("Pages.Auth.Label.new_pass_label")
                }}</label>
              </div>

              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password"
                  v-model="password"
                  class="form-control-merge"
                  :type="showPassword ? 'text' : 'password'"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'"
                    @click="togglePassword"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password-confirmation">{{
                  $t("Pages.Auth.Label.confirm_pass_label")
                }}</label>
              </div>
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="login-password-confirmation"
                  v-model="password_confirmation"
                  class="form-control-merge"
                  :type="showPassword ? 'text' : 'password'"
                  name="login-password-confirmation"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="showPassword ? 'EyeOffIcon' : 'EyeIcon'"
                    @click="togglePassword"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-button
              variant="primary"
              block
              type="submit"
              @click.prevent="ResetPassword"
            >
              {{ $t("Pages.Auth.Button.change_pass_btn") }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <span>{{ $t("Pages.Auth.Title.no_account") }} </span>
            <b-link :to="{ name: 'login' }">
              <span>&nbsp;{{ $t("Pages.Auth.Title.Login") }} </span>
            </b-link>
          </p>
          <!-- /////change lang section -->
          <b-card-text class="mb-0 text-center">
            <Locale />
          </b-card-text>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import {
  BRow,
  BCol,
  BLink,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from "bootstrap-vue";
import store from "@/store/index";
import { langInfoDao } from "@/daos/languages/langInfoDao";

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    Locale,
  },
  data() {
    return {
      showPassword: false,
      password_confirmation: "",
      password: "",
      langInfo: null,
      en_auth_banner: null,
      fa_auth_banner: null,
    };
  },
  async created() {
    this.getAllBanners();
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    ResetPassword() {
      if (
        this.$route.query.email &&
        this.$route.query.token &&
        this.password != "" &&
        this.password_confirmation != ""
      ) {
        const payload = {
          email: this.$route.query.email,
          token: this.$route.query.token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        };
        this.$http
          .post(`/api/resetPassword`, payload)
          .then((resp) => {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.susses_change_pass"),
              "success"
            );
            setTimeout(() => {
              this.$router.push("/login");
            }, 2000);
          })
          .catch(() => {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.invalid_token_change_pass"),
              "danger"
            );
            this.$VueCookies.remove("mastertube");
          });
      } else {
        this.makeToast(
          this.$t("Base.Alert.Error"),
          this.$t("Base.Alert.info_incomplete"),
          "danger"
        );
      }
    },
    async getAllBanners() {
      const langInfo= await langInfoDao.getLangInfo()
      this.langInfo=langInfo;
      const en_banners = langInfo.en.images;
      const fa_banners = langInfo.fa.images;
      this.en_auth_banner = en_banners.auth_banner;
      this.fa_auth_banner = fa_banners.auth_banner;
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
